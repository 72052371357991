<template>
  <v-btn v-if="checked" @click="action" :color="color" class="white--text" width="100%" depressed>{{
    text
  }}</v-btn>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    providerId: String,
    storeId: String,
    name: String,
    type: {
      type: String,
      required: true,
      validator: (value) => {
        return [
          "accept",
          "reject",
          "reApproved",
          "cancel",
          "reRequest",
        ].includes(value);
      },
    },
    routeName: String,
    to: Object,
    role: {
      type: String,
      default: "provider",
      validator: (value) => {
        return ["store", "provider"].includes(value);
      },
    },
  },
  computed: {
    ...mapGetters('member', ['currMemberRole']),
    checked() {
      let res = true
      if (['reject', 'cancel'].includes(this.type) && !this.currMemberRole._isMainManager) {
        res = false
      }
      return res
    },
    text() {
      return this.$t(`action.cooperation.${this.type}`);
    },
    color() {
      if (this.type == "accept") return "primary";
      if (["reApproved", "reRequest"].includes(this.type)) return "secondary";
      if (["reject", "cancel"].includes(this.type)) return "red";
      return "";
    },
    params() {
      return {
        status:
          this.$providerStoreCooperation.STORE_RELATIONSHIP_STATUS_COOPERATION,
      };
    },
    cancelApi() {
      if (this.role === "store"){
        this.$root.gaLogEvent('商店_點擊_取消合作')
        return this.$api.collection.storeApi.cancel
      } else {
        this.$root.gaLogEvent('供應商_點擊_取消合作')
        return this.$api.collection.providerApi.cancel
      }
    },
    updateStatusApi() {
      return this.$api.collection.providerApi.updateStatus;
    },
  },
  methods: {
    async action() {
      if (["reject", "reRequest"].includes(this.type)) {
        if (this.type === "reject") this.$root.gaLogEvent('供應商_點擊_拒絕合作')
        this.routerPush();
        return;
      }

      await this.updateStatus();
    },
    async updateStatus() {
      this.$store.dispatch("loading/active");
      try {       
        if (this.type === "cancel") {
          await this.cancelApi(this.providerId, this.storeId);
        } else {
          if (this.type === "accept") this.$root.gaLogEvent('供應商_點擊_同意合作')
          await this.updateStatusApi(
            this.providerId,
            this.storeId,
            this.params
          );
        }
        this.routerPush();
      } catch (err) {
        console.error(err);
        this.$snotify.error(this.$t(`action.cooperation.${this.type}.fail`));
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    routerPush() {
      if (this.type === 'accept') {
        this.$router.push({
          name: 'client-vipSelect',
          query: {
            storeName: this.name,
            storeId: this.storeId,
            providerId: this.providerId,
          },
        })
        return
      }

      if (this.to) {
        this.$router.push(this.to);
        return;
      }

      if (!this.routeName) return;
      this.$router.push({
        name: this.routeName,
        query: {
          name: this.name,
          storeId: this.storeId,
          providerId: this.providerId,
        },
      });
    },
  },
};
</script>